$(document).ready(function () {

    ProjectFunctions.AfterResize();
    ProjectFunctions.OrientationChange();
    //ProjectFunctions.AnzacTimer();

    MediaQueries.AddUserAgentAsClass();

    // Loads fastclick.js if we're on a touch device
    MediaQueries.FastClickInit();

    PageSetup.InitPage();
    //ProjectFunctions.StickySearch();

    //$(".btn-facebook").on("click", function (e) {
    //    e.preventDefault();
    //    var w = 626;
    //    var h = 436;
    //    var winTop = parseInt(($(window).height() / 2) - (h / 2));
    //    var winLeft = parseInt(($(window).width() / 2) - (w / 2));
    //    window.open("https://www.facebook.com/sharer/sharer.php?s=100&p[url]=" + location.href + "&p[title]=" + document.title + "&p[summary]=" + $(this).data("description") + "&p[images][0]=" + $(this).data("image"), "", "toolbar=0,status=0,top=" + winTop + ",left=" + winLeft + ",width=" + w + ",height=" + h);
    //});
});

var ProjectFunctions = (function () {
    return {

        AnzacTimer: function () {
            var countDownDate = new Date("Apr 25, 2021 06:00:00").getTime();
            var x = setInterval(function () {
                var now = new Date().getTime();
                var distance = countDownDate - now;
                var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                console.log($("#anzac-day-d"))
                $("#anzac-day-d").html(days.toString());
                $("#anzac-day-h").html(hours.toString());
                $("#anzac-day-m").html(minutes.toString());
                $("#anzac-day-s").html(seconds.toString());
                if (distance < 0) {
                    clearInterval(x);
                    $("#anzac-day-clock").addClass("hide");
                    $("#anzac-day-complete").removeClass("hide");
                }
            }, 1000);
        },

        AfterResize: function () {
            $(window).afterResize(function () {

                isDesktop = MediaQueries.MinWidth("1280px");

                isTablet = MediaQueries.MaxWidth("1279px") && MediaQueries.MinWidth("768px");

                isMobile = MediaQueries.MaxWidth("767px");

                $(".headered-box-content_map").scrollLeft(function () {
                    var scrollAmmount = Math.abs(($(this).outerWidth() - 790) / 2);
                    if (scrollAmmount > 0) return scrollAmmount;
                    else return 0;

                });

                ProjectFunctions.HeaderedBoxes();

            }, true, 200); // bool == runs on load or not, int (miliseconds) == how soon it runs after the resize event has finished
        },

        OrientationChange: function () {
            if (Modernizr.touch) {
                window.addEventListener("orientationchange", function () {
                    setTimeout(function () {

                        // Place any scripts here that need to be run after an orientation change

                    }, 200);

                });
            }
        },

        StickySearch: function () {
            var fixedElement = $(".section-search-mini"),
                fromTopDesktop = 46,
                fromTopTablet = 46,
                fromTopMobile = 46,
                hasSet = false;

            $("#autocomplete-category").on("focus input", function () {
                fixedElement.removeClass("hide-fields");
            });

            $(window).on("scroll load", function () {
                checkPosition();
            });

            // $(window).afterResize(function() {
            //     hasSet = false;
            //     checkPosition();
            // });

            function checkPosition() {
                var scrollTop = $(window).scrollTop();

                if (isDesktop) {
                    isFixed(fromTopDesktop, fixedElement);
                }
                else if (isTablet) {
                    isFixed(fromTopTablet, fixedElement);
                }
                else if (isMobile) {
                    isFixed(fromTopMobile, fixedElement);
                    fixedElement.addClass("hide-fields");
                }
            }

            function isFixed(distanceFromTop, element) {
                var scrollTop = $(window).scrollTop();

                if (scrollTop > distanceFromTop) {

                    if (hasSet === false) {
                        element.addClass("is-fixed");

                        if (isDesktop || isTablet) {
                            element.next().css({ "margin-top": element.outerHeight() + "px" });
                        }
                        else {
                            element.css({ "height": (element.find(".search-panel").outerHeight()) + "px" });
                        }
                    }

                    hasSet = true;

                }
                else {
                    element.removeClass("is-fixed").css({ "height": "" });
                    element.next().css({ "margin-top": "" });
                    hasSet = false;
                }
            }

        },

        ImageMap: function () {
            $("#ImageMap").find("area").hover(function () {
                var thisClass = $(this).attr("class"),
                    image = $("#" + thisClass);
                image.removeClass("visually-hidden");
            }, function () {
                var thisClass = $(this).attr("class"),
                    image = $("#" + thisClass);
                image.addClass("visually-hidden");
            });
        },

        HeaderedBoxes: function () {
            var importantContacts = $(".headered-box_important-contacts").first(),
                importantContactsContent = importantContacts.find(".headered-box-content").first(),
                importantContactsHeader = importantContacts.find(".headered-box-header").first(),
                needHelp = $(".headered-box_need-help").first(),
                needHelpContent = needHelp.find(".headered-box-content").first(),
                needHelpHeader = needHelp.find(".headered-box-header").first();

            if (isMobile) {
                importantContacts.addClass("is-closed");
                importantContactsContent.addClass("visually-hidden");
                needHelp.addClass("is-closed");
                needHelpContent.addClass("visually-hidden");

            }

            else {
                importantContacts.removeClass("is-closed");
                importantContactsContent.removeClass("visually-hidden");
                needHelp.removeClass("is-closed");
                needHelpContent.removeClass("visually-hidden");
            }

        },

        HeaderedBoxesClick: function () {
            var importantContacts = $(".headered-box_important-contacts").first(),
                importantContactsContent = importantContacts.find(".headered-box-content").first(),
                importantContactsHeader = importantContacts.find(".headered-box-header").first(),
                needHelp = $(".headered-box_need-help").first(),
                needHelpContent = needHelp.find(".headered-box-content").first(),
                needHelpHeader = needHelp.find(".headered-box-header").first();


            importantContactsHeader.click(function () {
                if (isMobile) {
                    importantContacts.toggleClass("is-closed");
                    if (importantContactsContent.hasClass("visually-hidden")) slideDown(importantContactsContent);
                    else slideUp(importantContactsContent);
                }
            });

            needHelpHeader.click(function () {
                if (isMobile) {
                    needHelp.toggleClass("is-closed");
                    if (needHelpContent.hasClass("visually-hidden")) slideDown(needHelpContent);
                    else slideUp(needHelpContent);
                }
            });

            function slideUp(selector) {
                selector.slideUp("fast", function () {
                    selector.addClass("visually-hidden")
                        .slideDown(0);
                });
            }

            function slideDown(selector) {
                selector.slideUp(0, function () {
                    selector.removeClass("visually-hidden")
                        .slideDown("fast");
                });
            }
        }

    };
})();
